
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import JoinusTop from "@/components/Utility/JoinusTop.vue";
@Component({
  components: {
    // Navbar,
    // Footer,
    JoinusTop,
  },
})
export default class About extends Vue {}
